.nav-background-initial {
  animation: animation-to-initial .3s;
  background: initial;
}

.nav-background-white {
  animation: animation-to-white .3s;
  background-color: rgba(255, 255, 255, 1);
}

.thape-header-fixed {
  position: fixed;
  z-index: 1000;
}

.thape-header {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  @apply text-thape-white-t80 nav-background-initial;

  // 默认文字样式
  a, span {
    color: inherit;
    @apply text-base;
  }

  nav {
    display: flex;

    &>.nav-item {
      @apply px-3;
      &:last-child {
        padding-right: 0;
      }
      @screen xxl {
        @apply px-4;
      }
    }

    &>.nav-point {
      cursor: default;
      display: flex;
      align-items: center;
    }

    .nav-item {

      >nav {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        @apply nav-background-initial border-gray-200 border-solid border-t;

        a, span {
          @apply text-sm;
        }
      }

      &:hover>nav {
        display: flex;
      }
    }

    .nav-item:hover>a, .nav-item:hover>span, a.active, span.active {
      @apply text-white font-semibold;
    }

    &.intro {
      height: 60px;
      align-items: center;
      justify-content: flex-end;
    }

    &.works {
      justify-content: flex-end;
      padding: .5rem 1.16rem .25rem 0;

      >.nav-item {

        .list {
          display: flex;
          margin-top: .5rem;

          .list-item {
            position: relative;
            width: 50%;

            a, span {
              @apply text-xs;
            }

            nav {
              position: absolute;
              top: 0;
              left: 100%;
              width: 100%;
              height: 100%;
              border: none;
              flex-direction: column;

              .nav-item {
                width: 125%;
                padding-left: 0;
                padding-right: 0;
              }
            }
          }
          &.area {
            .list-item {
              .nav-item {
                nav {
                  align-items: flex-start;
                  justify-content: flex-start;
                  flex-wrap: wrap;
                  .nav-item {
                    padding-right: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.service {
      height: 60px;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .menu-nav {
    display: none;
    height: 100%;

    &.show {
      @screen lg {
        display: flex;
      }
    }

    >.nav-item {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .header-search-form {
    display: none;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 25vw;
    height: calc(100% - 10px);
    align-items: center;
    padding: 0 0 0 1.8rem;

    &.show {
      display: none;
      @screen lg {
        display: flex;
      }
    }

    input {
      margin-top: 2px;
      flex: 1 1 100%;
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      background: transparent;
      &:focus {
        outline: none;
        border: none;
      }
    }

    .underline {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      @apply bg-gray-200;
    }

    button {
      position: absolute;
      left: 0;
      top: calc(50% - 0.75rem);
    }
    input, input::placeholder, button {
      @apply text-thape-white-t80;
    }
  }

  &:hover, &.active {
    @apply text-thape-copyright-gray nav-background-white;

    nav {
      &>.nav-point {
        @apply text-thape-copyright-gray;
      }
      nav {
        @apply nav-background-white;
      }
    }

    .nav-item:hover>a, .nav-item:hover>span, a.active, span.active {
      @apply text-black6c font-semibold;
    }

    >.logo {
      a {
        @apply text-black6c;
      }
      a:hover, a:focus{
        @apply text-black6c-t70;
      }
    }

    .header-search-form {
      input, input::placeholder, button {
        @apply text-thape-copyright-gray;
      }
    }
  }


  &.big-font {
    a, span {
      @apply text-xl;
    }

    >nav .nav-item nav {
      a, span {
        @apply text-base #{!important};
      }
    }
  }

  // 作品
  & nav.works>.nav-item {
    &:first-child {
      width: 16rem;
    }

    &:nth-child(2) {
      width: 12rem;
    }
  }

  // 英文版作品
  &:lang(en) nav.works>.nav-item {
    &:first-child {
      width: 26rem;
    }
    &:nth-child(2) {
      width: 28rem;
    }
  }

  // 大字版作品
  &.big-font nav.works >.nav-item {
    &:first-child {
      width: 16rem;
    }
    &:nth-child(2) {
      width: 14rem;
    }
  }

  // 大字版英文作品
  &.big-font:lang(en) nav.works >.nav-item {
    &:first-child {
      width: 34rem;
    }
    &:nth-child(2) {
      width: 30rem;
    }
  }

  .aside-menu-button {
    display: block;
    @apply rounded-md text-thape-white-t80 transition duration-150 ease-in-out;

    @screen lg {
      display: none;
    }

    &, &:focus {
      outline: none;
    }
  }
  &.active .aside-menu-button, &:hover .aside-menu-button {
    @apply text-gray-400;
  }

  .mobile-search {
    display: none;
    &.show {
      display: block;
      @screen lg {
        display: none;
      }
    }
  }

  .aside-menu {
    display: none;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: calc(100vh - 50px);
    background-color: transparent;

    &.show {
      display: block;
      @screen lg {
        display: none;
      }
    }

    .aside-menu-container {
      @apply p-6 nav-background-white shadow-md border-gray-200 border-solid border-t;

      .aside-menu-item {
        a {
          display: block;
          margin-bottom: .5rem;
        }

        .aside-menu-children {
          padding-left: 1rem;
        }
      }
    }
  }

  @screen lg {
    height: 80px;

    nav.intro {
      padding-right: 22.7rem;
    }

    &:lang(en) nav.intro {
      padding-right: 15.8rem;
    }

    &.big-font nav.intro {
      padding-right: 24.7rem;
    }

    &.big-font:lang(en) nav.intro {
      padding-right: 17rem;
    }

    nav.works {
      padding-right: 10.2rem;
    }

    &:lang(en) nav.works {
      padding-right: 4rem;
    }

    &.big-font nav.works {
      padding-right: 11.7rem;
    }

    &.big-font:lang(en) nav.works {
      padding-right: 4rem;
    }

    nav.service {
      @apply pr-8;

      >.nav-item {
        padding: 0 1.4rem;
      }
    }

    &.big-font nav.service>.nav-item {
      padding: 0 1.47rem;
    }
  }
  @screen xl {

    nav.intro {
      padding-right: 23.2rem;
    }

    &:lang(en) nav.intro {
      padding-right: 25.6rem;
    }

    &.big-font nav.intro {
      padding-right: 25.2rem;
    }

    &.big-font:lang(en) nav.intro {
      padding-right: 25.9rem;
    }

    nav.works {
      padding-right: 10.7rem;
    }

    &:lang(en) nav.works {
      padding-right: 4.4rem;
    }

    &.big-font nav.works {
      padding-right: 12.1rem;
    }

    &.big-font:lang(en) nav.works {
      padding-right: 5.8rem;
    }

    nav.service {
      @apply pr-10;

      >.nav-item {
        padding: 0 1.4rem;
      }
    }

    &.big-font nav.service>.nav-item {
      padding: 0 1.47rem;
    }
  }
  @screen xxl {

    nav.intro {
      padding-right: 26.7rem;
    }

    &:lang(en) nav.intro {
      padding-right: 29.1rem;
    }

    &.big-font nav.intro {
      padding-right: 28.8rem;
    }

    &.big-font:lang(en) nav.intro {
      padding-right: 33.2rem;
    }

    nav.works {
      padding-right: 15.4rem;
    }

    &:lang(en) nav.works {
      padding-right: 4.8rem;
    }

    &.big-font nav.works {
      padding-right: 16.8rem;
    }

    &.big-font:lang(en) nav.works {
      padding-right: 6.4rem;
    }

    nav.service {
      @apply pr-12;

      >.nav-item {
        padding: 0 1.69rem;
      }
    }

    &.big-font nav.service>.nav-item {
      padding: 0 1.76rem;
    }
  }
  @screen xxxl {

    nav.intro {
      padding-right: 27.7rem;
    }

    &:lang(en) nav.intro {
      padding-right: 30.1rem;
    }

    &.big-font nav.intro {
      padding-right: 29.7rem;
    }

    &.big-font:lang(en) nav.intro {
      padding-right: 34.2rem;
    }

    nav.works {
      padding-right: 16.5rem;
    }

    &:lang(en) nav.works {
      padding-right: 5.9rem;
    }

    &.big-font nav.works {
      padding-right: 17.9rem;
    }

    &.big-font:lang(en) nav.works {
      padding-right: 7.2rem;
    }

    nav.service {
      @apply pr-16;

      >.nav-item {
        padding: 0 1.69rem;
      }
    }

    &.big-font nav.service>.nav-item {
      padding: 0 1.76rem;
    }
  }

  &:lang(en) nav.service>.nav-item {
    padding: 0 .85rem;
  }

  nav.service>.nav-item:last-child {
    padding-right: 0;
  }
}
