.publications {
  .list {
    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @screen lg {
        flex-direction: column;
        justify-content: flex-start;
      }

      .cover {
        flex: none;
        position: relative;
        width: calc(50% - 1rem);
        height: 0;
        padding-bottom: calc((50% - 1rem) * 1.2857);

        @screen lg {
          width: 100%;
          padding-bottom: 100% * 1.2857;
        }

        .wrapper {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;

          $base_y: -3deg;
          $base_add_y: -20deg;
        
          $front_y: $base_y + -12deg;
          $hover_front_y: $base_y + -119deg + $base_add_y;
        
          $page1_y: $base_y + -10deg;
          $hover_page1_y: $base_y + -114deg + $base_add_y;
        
          $page2_y: $base_y + -8deg;
          $hover_page2_y: $base_y + -104deg + $base_add_y;
        
          $page3_y: $base_y + -6deg;
          $hover_page3_y: $base_y + -92deg + $base_add_y;
        
          $page4_y: $base_y + -4deg;
          $hover_page4_y: $base_y + -9deg;
        
          $page5_y: $base_y + -2deg;
          $hover_page5_y: $base_y + -4deg;

          $back_y: $base_y;

          .book {
            position: relative;
            width: 100%;
            height: 100%;
            perspective: 1000px;
            transform-style: preserve-3d;

            .book-front {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              transform: rotateY($front_y);
              transform-origin: left center;
              transition: all 0.4s ease;
              transform-style: preserve-3d;
              z-index: 100;

              >:first-child {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform-origin: left center;
                transform: rotateY(0);

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              >:last-child {
                position: absolute;
                top: 4px;
                bottom: 4px;
                left: 0;
                right: 4px;
                background: #fffbec;
                transform-origin: left center;
                transform: rotateY(1deg);

                &::before {
                  content: '';
                  position: absolute;
                  bottom: -4px;
                  left: 10px;
                  right: 10px;
                  height: 4px;
                  background: transparent;
                  box-shadow: 0px 0px 30px 5px rgba(51, 51, 51, 0.8);
                  transform: rotateX(80deg);
                }
              }
            }

            @screen lg {
            &:hover {
              .book-front {
                transform: rotateY($hover_front_y);
              }
            }
            }

            .book-page {
              position: absolute;
              top: 5px;
              bottom: 5px;
              left: 0;
              right: 5px;
              backface-visibility: visible;
              transition-timing-function: ease;
              transition-property: transform;
              transform-origin: left center;
              background: linear-gradient(to right, #e1ddd8 0%, #fffbf6 100%);
              box-shadow: inset 0px -1px 2px rgba(50, 50, 50, 0.1), inset -1px 0px 1px rgba(150, 150, 150, 0.2);
              border-radius: 0px 5px 5px 0px;

              &.book-page-1 {
                right: -1px;
                transition-duration: 0.3s;
                transform: rotateY($page1_y);
                z-index: 99;
              }
              &.book-page-2 {
                right: -2px;
                transition-duration: 0.25s;
                transform: rotateY($page2_y);
                z-index: 98;
              }
              &.book-page-3 {
                right: -3px;
                transition-duration: 0.2s;
                transform: rotateY($page3_y);
                z-index: 97;
              }
              &.book-page-4 {
                right: -4px;
                transition-duration: 0.3s;
                transform: rotateY($page4_y);
                z-index: 3;
              }
              &.book-page-5 {
                right: -5px;
                transition-duration: 0.3s;
                transform: rotateY($page5_y);
                z-index: 2;
              }
            }

            @screen lg {
            &:hover {
              .book-page {
                &.book-page-1 {
                  transform: rotateY($hover_page1_y);
                  transition-duration: 0.6s;
                }
                &.book-page-2 {
                  transform: rotateY($hover_page2_y);
                  transition-duration: 0.7s;
                }
                &.book-page-3 {
                  transform: rotateY($hover_page3_y);
                  transition-duration: 0.8s;
                }
                &.book-page-4 {
                  transform: rotateY($hover_page4_y);
                  transition-duration: 0.9s;
                }
                &.book-page-5 {
                  transform: rotateY($hover_page5_y);
                  transition-duration: 0.75s;
                }
              }
            }
            }

            .book-back {
              position: absolute;
              top: 0;
              left: 0;
              width: calc(100% + 12px);
              height: 100%;
              transform: rotateY($back_y);
              transform-origin: left center;
              transform-style: preserve-3d;
              z-index: 1;

              >:first-child {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform-origin: left center;
                transform: rotateY(0deg);
        
                img {
                  width: 100%;
                  height: 100%;
                }
              }

              >:last-child {
                position: absolute;
                top: 4px;
                bottom: 4px;
                left: 0;
                right: 4px;
                background: #fffbec;
                transform-origin: left center;
                transform: rotateY(-1deg);

                &::before {
                  content: '';
                  position: absolute;
                  bottom: -4px;
                  left: 10px;
                  right: 10px;
                  height: 4px;
                  background: transparent;
                  box-shadow: 0px 0px 30px 5px rgba(51, 51, 51, 0.8);
                  transform: rotateX(80deg);
                }
              }
            }
          }

          .mobile-book {
            position: relative;

            &::before {
              content: '';
              display: block;
              width: 18.4%;
              height: 4.3%;
              position: absolute;
              left: -8.7%;
              bottom: 0.7%;
              background: url('../images/book-shadow-left.png') no-repeat center / cover;
              z-index: 10;
            }

            img {
              position: relative;
              z-index: 100;
            }

            &::after {
              content: '';
              display: block;
              display: block;
              width: 25.3%;
              height: 5.8%;
              position: absolute;
              right: -11.7%;
              bottom: -1.3%;
              background: url('../images/book-shadow-right.png') no-repeat center / cover;
              z-index: 10;
            }
          }
        }
      }

      .description {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex: none;
        width: calc(50% - 1rem);

        @screen lg {
          width: 100%;
          justify-content: flex-start;
        }

        .title {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          overflow: hidden;
        }
      }
    }
  }
}
