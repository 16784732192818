@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
  }
  
  to {
    transform: translateX(-100%);
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
  }
  
  to {
    transform: translateX(100%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeOutLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes fadeOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.animate {
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-direction: normal;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.slide-in-left {
  animation-name: slideInLeft;
}

.slide-in-right {
  animation-name: slideInRight;
}

.slide-out-left {
  animation-name: slideOutLeft;
}

.slide-out-right {
  animation-name: slideOutRight;
}

.fade-in {
  animation-name: fadeIn;
}

.fade-out {
  animation-name: fadeOut;
}

.fade-in-left {
  animation-name: fadeInLeft;
}

.fade-in-right {
  animation-name: fadeInRight;
}

.fade-out-left {
  animation-name: fadeOutLeft;
}

.fade-out-right {
  animation-name: fadeOutRight;
}
