.hover-scale {
    .scale-img {
        transform: translateZ(0) scale(1);
        transition: transform ease .7s;
    }
    &:hover {
        .scale-img {
            transform: translateZ(0) scale(1.05);
        }
    }
}

.card-title-mask {
    background-image: linear-gradient(to top, rgba(0, 0, 0, .8),  rgba(0, 0, 0, 0));
}

.works-projects-container {
    position: relative;
    width: 100%;
    padding-top: 500%;
    @screen sm {
        padding-top: 212%;
    }
    @screen xl {
        padding-top: 114%;
    }

    .mask {
        background-image: linear-gradient(to top, rgba(0, 0, 0, .8),  rgba(0, 0, 0, 0));
    }

    .works-projects-grid {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: grid;
        grid-template: repeat(11, 1fr) / repeat(2, 1fr);
        gap: .25rem;

        .works-projects-cell {
            position: relative;
            overflow: hidden;

            &.slogan {
                grid-area: 1 / 1 / 1 / 3;
            }
    
            // 居住
            &.resdential {
                grid-area: 2 / 1 / 2 / 3;
            }
    
            // 示范区
            &.demonstration-zone {
                grid-area: 3 / 1 / 3 / 1;
            }
    
            // 综合体
            &.mixed-used-tod {
                grid-area: 4 / 1 / 4 / 3;
            }
    
            // 商业空间
            &.commercial {
                grid-area: 3 / 2 / 3 / 2;
            }
    
            // 超高层
            &.supertall {
                grid-area: 5 / 1 / 7 / 1;
            }
    
            // 文化
            &.cultural {
                grid-area: 7 / 1 / 7 / 3;
            }
    
            // 教育
            &.education {
                grid-area: 6 / 2 / 6 / 2;
            }
    
            // 商务办公
            &.office {
                grid-area: 5 / 2 / 5 / 2;
            }
    
            // 城市设计/更新
            &.urban-design {
                grid-area: 8 / 2 / 10 / 2;
            }
    
            // 医疗康养
            &.medical-care {
                grid-area: 8 / 1 / 8 / 1;
            }
    
            // 旅居/酒店
            &.hospitality {
                grid-area: 10 / 1 / 10 / 3;
            }
    
            // 规划
            &.urban-planning {
                grid-area: 9 / 1 / 9 / 1;
            }
    
            // 景观
            &.landscape {
                grid-area: 11 / 1 / 11 / 1;
            }
    
            // 室内
            &.interior {
                grid-area: 11 / 2 / 11 / 2;
            }
        }
        
        @screen sm {
            grid-template: repeat(7, 1fr) / repeat(3, 1fr);
            gap: .5rem;
            .works-projects-cell {

                &.slogan {
                    grid-area: 1 / 1 / 1 / 1;
                }
        
                // 居住
                &.resdential {
                    grid-area: 1 / 2 / 1 / 4;
                }
        
                // 示范区
                &.demonstration-zone {
                    grid-area: 2 / 3 / 2 / 3;
                }
        
                // 综合体
                &.mixed-used-tod {
                    grid-area: 3 / 2 / 3 / 4;
                }
        
                // 商业空间
                &.commercial {
                    grid-area: 4 / 3 / 4 / 3;
                }
        
                // 超高层
                &.supertall {
                    grid-area: 2 / 1 / 4 / 1;
                }
        
                // 文化
                &.cultural {
                    grid-area: 4 / 1 / 4 / 3;
                }
        
                // 教育
                &.education {
                    grid-area: 5 / 2 / 5 / 2;
                }
        
                // 商务办公
                &.office {
                    grid-area: 2 / 2 / 2 / 2;
                }
        
                // 城市设计/更新
                &.urban-design {
                    grid-area: 5 / 1 / 7 / 1;
                }
        
                // 医疗康养
                &.medical-care {
                    grid-area: 5 / 3 / 5 / 3;
                }
        
                // 旅居/酒店
                &.hospitality {
                    grid-area: 6 / 2 / 6 / 4;
                }
        
                // 规划
                &.urban-planning {
                    grid-area: 7 / 1 / 7 / 1;
                }
        
                // 景观
                &.landscape {
                    grid-area: 7 / 2 / 7 / 2;
                }
        
                // 室内
                &.interior {
                    grid-area: 7 / 3 / 7 / 3;
                }
            }
        }

        @screen xl {
            grid-template: repeat(5, 1fr) / repeat(4, 1fr);
            gap: .5rem;
            .works-projects-cell {
    
                &.slogan {
                    grid-area: 1 / 1 / 1 / 1;
                }
        
                // 居住
                &.resdential {
                    grid-area: 1 / 2 / 1 / 4;
                }
        
                // 示范区
                &.demonstration-zone {
                    grid-area: 1 / 4 / 1 / 4;
                }
        
                // 综合体
                &.mixed-used-tod {
                    grid-area: 2 / 1 / 2 / 3;
                }
        
                // 商业空间
                &.commercial {
                    grid-area: 2 / 3 / 2 / 3;
                }
        
                // 超高层
                &.supertall {
                    grid-area: 2 / 4 / 4 / 4;
                }
        
                // 文化
                &.cultural {
                    grid-area: 3 / 1 / 3 / 1;
                }
        
                // 教育
                &.education {
                    grid-area: 3 / 2 / 3 / 2;
                }
        
                // 商务办公
                &.office {
                    grid-area: 3 / 3 / 3 / 3;
                }
        
                // 城市设计/更新
                &.urban-design {
                    grid-area: 4 / 1 / 6 / 1;
                }
        
                // 医疗康养
                &.medical-care {
                    grid-area: 4 / 2 / 4 / 2;
                }
        
                // 旅居/酒店
                &.hospitality {
                    grid-area: 4 / 3 / 4 / 5;
                }
        
                // 规划
                &.urban-planning {
                    grid-area: 5 / 2 / 5 / 2;
                }
        
                // 景观
                &.landscape {
                    grid-area: 5 / 3 / 5 / 3;
                }
        
                // 室内
                &.interior {
                    grid-area: 5 / 4 / 5 / 4;
                }
            }
        }
    }

    .works-projects-block {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        @mixin cellPositionSize($gap, $cols, $rows, $col, $row, $colSpan, $rowSpan) {
            top: calc(((100% - #{$gap * ($rows - 1)}) / #{$rows} + #{$gap}) * #{$row - 1});
            left: calc(((100% - #{$gap * ($cols - 1)}) / #{$cols} + #{$gap}) * #{$col - 1});
            width: calc((100% - #{$gap * ($cols - 1)}) / #{$cols} * #{$colSpan} + #{$gap * ($colSpan - 1)});
            height: calc((100% - #{$gap * ($rows - 1)}) / #{$rows} * #{$rowSpan} + #{$gap * ($rowSpan - 1)});
        }

        .works-projects-cell {
            position: absolute;
            overflow: hidden;
            $gap: .25rem;
            $cols: 2;
            $rows: 11;
            &.slogan {
                @include cellPositionSize($gap, $cols, $rows, 1, 1, 2, 1);
            }
    
            // 居住
            &.resdential {
                @include cellPositionSize($gap, $cols, $rows, 1, 2, 2, 1);
            }
    
            // 示范区
            &.demonstration-zone {
                @include cellPositionSize($gap, $cols, $rows, 1, 3, 1, 1);
            }
    
            // 综合体
            &.mixed-used-tod {
                @include cellPositionSize($gap, $cols, $rows, 1, 4, 2, 1);
            }
    
            // 商业空间
            &.commercial {
                @include cellPositionSize($gap, $cols, $rows, 2, 3, 1, 1);
            }
    
            // 超高层
            &.supertall {
                @include cellPositionSize($gap, $cols, $rows, 1, 5, 1, 2);
            }
    
            // 文化
            &.cultural {
                @include cellPositionSize($gap, $cols, $rows, 1, 7, 2, 1);
            }
    
            // 教育
            &.education {
                @include cellPositionSize($gap, $cols, $rows, 2, 6, 1, 1);
            }
    
            // 商务办公
            &.office {
                @include cellPositionSize($gap, $cols, $rows, 2, 5, 1, 1);
            }
    
            // 城市设计/更新
            &.urban-design {
                @include cellPositionSize($gap, $cols, $rows, 2, 8, 1, 2);
            }
    
            // 医疗康养
            &.medical-care {
                @include cellPositionSize($gap, $cols, $rows, 1, 8, 1, 1);
            }
    
            // 旅居/酒店
            &.hospitality {
                @include cellPositionSize($gap, $cols, $rows, 1, 10, 2, 1);
            }
    
            // 规划
            &.urban-planning {
                @include cellPositionSize($gap, $cols, $rows, 1, 9, 1, 1);
            }
    
            // 景观
            &.landscape {
                @include cellPositionSize($gap, $cols, $rows, 1, 11, 1, 1);
            }
    
            // 室内
            &.interior {
                @include cellPositionSize($gap, $cols, $rows, 2, 11, 1, 1);
            }
        }
        
        @screen sm {
            .works-projects-cell {
                $gap: .5rem;
                $cols: 3;
                $rows: 7;
                &.slogan {
                    @include cellPositionSize($gap, $cols, $rows, 1, 1, 1, 1);
                }
        
                // 居住
                &.resdential {
                    @include cellPositionSize($gap, $cols, $rows, 2, 1, 2, 1);
                }
        
                // 示范区
                &.demonstration-zone {
                    @include cellPositionSize($gap, $cols, $rows, 3, 2, 1, 1);
                }
        
                // 综合体
                &.mixed-used-tod {
                    @include cellPositionSize($gap, $cols, $rows, 2, 3, 2, 1);
                }
        
                // 商业空间
                &.commercial {
                    @include cellPositionSize($gap, $cols, $rows, 3, 4, 1, 1);
                }
        
                // 超高层
                &.supertall {
                    @include cellPositionSize($gap, $cols, $rows, 1, 2, 1, 2);
                }
        
                // 文化
                &.cultural {
                    @include cellPositionSize($gap, $cols, $rows, 1, 4, 2, 1);
                }
        
                // 教育
                &.education {
                    @include cellPositionSize($gap, $cols, $rows, 2, 5, 1, 1);
                }
        
                // 商务办公
                &.office {
                    @include cellPositionSize($gap, $cols, $rows, 2, 2, 1, 1);
                }
        
                // 城市设计/更新
                &.urban-design {
                    @include cellPositionSize($gap, $cols, $rows, 1, 5, 1, 2);
                }
        
                // 医疗康养
                &.medical-care {
                    @include cellPositionSize($gap, $cols, $rows, 3, 5, 1, 1);
                }
        
                // 旅居/酒店
                &.hospitality {
                    @include cellPositionSize($gap, $cols, $rows, 2, 6, 2, 1);
                }
        
                // 规划
                &.urban-planning {
                    @include cellPositionSize($gap, $cols, $rows, 1, 7, 1, 1);
                }
        
                // 景观
                &.landscape {
                    @include cellPositionSize($gap, $cols, $rows, 2, 7, 1, 1);
                }
        
                // 室内
                &.interior {
                    @include cellPositionSize($gap, $cols, $rows, 3, 7, 1, 1);
                }
            }
        }

        @screen xl {
            $gap: .5rem;
            $cols: 4;
            $rows: 5;
            .works-projects-cell {
                &.slogan {
                    @include cellPositionSize($gap, $cols, $rows, 1, 1, 1, 1);
                }
        
                // 居住
                &.resdential {
                    @include cellPositionSize($gap, $cols, $rows, 2, 1, 2, 1);
                }
        
                // 示范区
                &.demonstration-zone {
                    @include cellPositionSize($gap, $cols, $rows, 4, 1, 1, 1);
                }
        
                // 综合体
                &.mixed-used-tod {
                    @include cellPositionSize($gap, $cols, $rows, 1, 2, 2, 1);
                }
        
                // 商业空间
                &.commercial {
                    @include cellPositionSize($gap, $cols, $rows, 3, 2, 1, 1);
                }
        
                // 超高层
                &.supertall {
                    @include cellPositionSize($gap, $cols, $rows, 4, 2, 1, 2);
                }
        
                // 文化
                &.cultural {
                    @include cellPositionSize($gap, $cols, $rows, 1, 3, 1, 1);
                }
        
                // 教育
                &.education {
                    @include cellPositionSize($gap, $cols, $rows, 2, 3, 1, 1);
                }
        
                // 商务办公
                &.office {
                    @include cellPositionSize($gap, $cols, $rows, 3, 3, 1, 1);
                }
        
                // 城市设计/更新
                &.urban-design {
                    @include cellPositionSize($gap, $cols, $rows, 1, 4, 1, 2);
                }
        
                // 医疗康养
                &.medical-care {
                    @include cellPositionSize($gap, $cols, $rows, 2, 4, 1, 1);
                }
        
                // 旅居/酒店
                &.hospitality {
                    @include cellPositionSize($gap, $cols, $rows, 3, 4, 2, 1);
                }
        
                // 规划
                &.urban-planning {
                    @include cellPositionSize($gap, $cols, $rows, 2, 5, 1, 1);
                }
        
                // 景观
                &.landscape {
                    @include cellPositionSize($gap, $cols, $rows, 3, 5, 1, 1);
                }
        
                // 室内
                &.interior {
                    @include cellPositionSize($gap, $cols, $rows, 4, 5, 1, 1);
                }
            }
        }
    }
}