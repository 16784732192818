@import "~@webwingscz/googlefont-montserrat/montserrat";
@import 'animate';
@import 'flex-grid';
@tailwind base;
// autoprefixer: Replace color-adjust to print-color-adjust. The color-adjust shorthand is currently deprecated.
// happen below, you can comment out while developing:
@tailwind components;

@import 'nav-bar';
@import 'works';
@import 'publications';
@import 'home';
@tailwind utilities;

@layer utilities {
  @responsive {
    .rounded-xl {
      border-radius: 1.25rem;
    }
  }
}

* {
  -webkit-tap-highlight-color:transparent;
}

img{
  image-rendering: crisp-edges;
}

.h1-cap {
  @apply border-gray-900 border-solid;
  padding-bottom: .25rem;
}

.breadcrumb-item {
  display: inline-block;
  padding-left: .125rem;

  &::before {
    color: rgba(16,24,32,0.7);
    content: '>';
    display: inline-block; // Suppress underlining of the separator in modern browsers
    padding-right: .125rem;
  }
}

.header-item {
  display: inline-block;
  padding-left: .125rem;

  &::before {
    color: rgba(16,24,32,0.7);
    content: '|';
    display: inline-block;
    padding-right: .125rem;
  }
}

.header-item-last {
  display: inline-block;

  &::before {
    color: rgba(16,24,32,0.7);
    content: '|';
    display: inline-block;
    padding-right: .125rem;
  }

  &::after {
    color: rgba(16,24,32,0.7);
    content: '|';
    display: inline-block;
    padding-left: .125rem;
  }
}

.w-18 {
  width: 4.475rem;
}

.w-80 {
  width: 18rem;
}

.mr-45x {
  margin-right: 9.75rem;
}

.screen-min-height {
  min-height: calc(100vh - 13.875rem);
}

.filter-blur {
  filter: blur(2px);
}

.circle-border {
  border-radius: 50%;
  display: inline-block;
  height: 25px;
  margin-right: .25rem;
  width: 25px;
}

.map-content-dot {
  content: "⦿";
  position: absolute;
  top: -1px;
}

.map-content-star {
  content: "★";
  position: absolute;
  top: -6px;
  font-size: 16px;
  color: #f50000;
}

.map-dot-b::before {
  @extend .map-content-dot;
  left: -15px;
}

.map-dot-a::after {
  @extend .map-content-dot;
  right: -15px;
}

.map-dot-t::before {
  @extend .map-content-dot;
  top: 15px;
  left: 8px;
}

.map-dot-d::before {
  @extend .map-content-dot;
  top: -15px;
  left: 12px;
}

.map-star-b::before {
  @extend .map-content-star;
  left: -16px;
}

.map-star-a::after {
  @extend .map-content-star;
  right: -16px;
}

.map-star-t::before {
  @extend .map-content-star;
  top: 15px;
  left: 8px;
}

.map-star-d::before {
  @extend .map-content-star;
  top: -18px;
  left: 12px;
}

p {
  text-align: justify;
}

@keyframes animation-to-white {
  0% {
    background-color: initial;
  }
  100% {
    background-color: rgba(255, 255, 255, 1);
  }
}

@keyframes animation-to-initial {
  0% {
    background-color: rgba(255, 255, 255, 1);
  }
  100% {
    background-color: initial;
  }
}

.main>* {
  width: 100%;
}

.thape-slide {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .slide-images {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .image-item {
      position: absolute;
      left: 0;
      top: 0;
      display: none;
      width: 100%;
      height: 100%;
      overflow: hidden;
      line-height: 0;
    }

    .image-item.active {
      display: block;
    }

    .image-item img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
      user-select: none;
    }
  }
}

.action-area {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  cursor: pointer;
  color: white;
  @screen md {
    opacity: 0;
    &:hover {
      opacity: 1;
    }
    &.active {
      opacity: 1;
    }
  }
}

.action-left {
  left: 0;
  @screen md {
    background-image: linear-gradient(to right, rgba(0,0,0,.6), rgba(0,0,0,0));
  }
  &:hover {
    background-image: linear-gradient(to right, rgba(0,0,0,.6), rgba(0,0,0,0));
  }
  &.active {
    background-image: linear-gradient(to right, rgba(0,0,0,.6), rgba(0,0,0,0));
  }
}

.action-right {
  right: 0;
  @screen md {
    background-image: linear-gradient(to left, rgba(0,0,0,.6), rgba(0,0,0,0));
  }
  &:hover {
    background-image: linear-gradient(to left, rgba(0,0,0,.6), rgba(0,0,0,0));
  }
  &.active {
    background-image: linear-gradient(to left, rgba(0,0,0,.6), rgba(0,0,0,0));
  }
}

.thape-logo-svg {
  @apply fill-current w-32 h-10;
  &.big-font {
    width: 18rem;
  }
  @screen sm{
    @apply fill-current w-48;
    &.big-font {
      width: 14rem;
    }
  }
}

.news-img > p img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
